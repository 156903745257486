 <template>
 <div>

  <div class="flex  flex-col text-sm md:pl-10 md:pr-10">
                <div class="flex justify-end">

                      <div>
                        <v-btn
                          color="primary"
                          dark
                          class="mb-2"
                          @click="addUsuario()"
                        >
                          Nuevo usuario
                        </v-btn>
                      </div>                      

                </div>                      

                <v-data-table
                  :headers="headers"
                  :items="usuarios"
                  sort-by="nu_id_usuario"
                  class="elevation-1"
                  
                >
                  <template v-slot:top>
                    <v-toolbar
                      flat
                    >
                      <v-toolbar-title>
                        <v-icon
                          large
                          dense
                          color="green darken-1"
                        >
                          mdi-account
                        </v-icon>                           
                      </v-toolbar-title>
                      <v-divider
                        class="mx-4"
                        inset
                        vertical
                      ></v-divider>
                      <v-spacer></v-spacer>
                      <v-dialog
                        v-model="dialog"
                        content-class="vdialognew" 
                        max-width="800px"
                      >
                        <v-card>
                          <v-card-title>
                            <span class="headline">{{ formTitle }}</span>
                          </v-card-title>

                          <v-card-text>
                            <v-container>
                              <v-col>
                                <v-row
                                  cols="12"
                                  sm="6"
                                  md="4"
                                >
                              <v-col>
                                  <v-text-field
                                    v-model="editedItem.tx_username"
                                    disabled
                                    label="Usuario"
                                  ></v-text-field>

                              </v-col>
                                </v-row>
                                    <v-row
                                      cols="12"
                                      sm="6"
                                      md="4"
                                    >
                                      <v-col>
                                          <v-text-field
                                            v-model="editedItem.tx_nombres"
                                            disabled
                                            label="Nombres"
                                          ></v-text-field>
                                      </v-col>
                                      </v-row>
                                      <v-row
                                          cols="12"
                                          sm="6"
                                          md="4"
                                        >                                      
                                      <v-col>
                                          <v-text-field
                                            v-model="editedItem.tx_apellidos"
                                            disabled
                                            label="Apellidos"
                                          ></v-text-field>

                                      </v-col>
                                    </v-row>
                                    <v-row
                                      cols="12"
                                      sm="6"
                                      md="4"
                                    >
                                          <v-select
                                            v-model="editedItem.co_status"
                                            :items="d_status"
                                            class="input-group--focused"
                                            label="Status"
                                            dense
                                          ></v-select>                                           

                                    </v-row>
                                    <v-row
                                      cols="12"
                                      sm="6"
                                      md="4"
                                    >
                                      <v-col>
                                          <v-text-field
                                            v-model="editedItem.tx_direccion"
                                            label="Dirección"
                                          ></v-text-field>
                                      </v-col>

                                    </v-row>



                                <v-row
                                  cols="12"
                                  sm="6"
                                  md="4"
                                >
                                <div class="w-full">

                                    <v-list>
                                      <v-list-group
                                        :value="false"
                                        prepend-icon="mdi-account-circle"
                                      >
                                        <template v-slot:activator>
                                          <v-list-item-title>Perfiles</v-list-item-title>
                                        </template>

                                      <v-list-item 
                                        v-for="(item, index) in perfiles" :key="index">
                                            <v-list-item-action>
                                                <v-radio-group v-model="editedItem.usuario_perfil">
                                                  <v-radio
                                                    :label=perfiles[index].tx_desc_perfil
                                                    :value=perfiles[index].nu_id_perfil
                                                  ></v-radio>
                                                </v-radio-group>                                              
                                            </v-list-item-action>

                                            <v-list-item-content>
                                              <v-list-item-title>{{ perfiles[index].tx_desc_perfil }}</v-list-item-title>
                                            </v-list-item-content>
                                      </v-list-item>
                                      </v-list-group>
                                    </v-list>                                  

                                </div>                                
                                </v-row>
                                
                              </v-col>
                            </v-container>
                          </v-card-text>

                          <v-card-actions class="mb-10">
                            <v-spacer></v-spacer>
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="close"
                            >
                              Cerrar ventana
                            </v-btn>

                          </v-card-actions>
                        </v-card>
                      </v-dialog>

                      <v-dialog
                        v-model="dialogAdd"
                        content-class="" 
                        max-width="800px"
                      >
                        <v-card>
                          <v-card-title>
                            <span class="headline">{{ formTitle }}</span>
                          </v-card-title>

                          <v-card-text>
                            <v-container>
                              <v-col>
                                <v-row
                                  cols="12"
                                  sm="6"
                                  md="4"
                                >
                              <v-col>
                                  <v-text-field
                                    v-model="editedItem.tx_username"
                                    label="Usuario"
                                  ></v-text-field>

                              </v-col>
                                </v-row>

                                    <v-row
                                      cols="12"
                                      sm="6"
                                      md="4"
                                    >
                                      <v-col>
                                          <v-text-field
                                            v-model="editedItem.tx_nombres"
                                            label="Nombres"
                                          ></v-text-field>
                                      </v-col>
                                      </v-row>
                                      <v-row
                                          cols="12"
                                          sm="6"
                                          md="4"
                                        >                                      
                                      <v-col>
                                          <v-text-field
                                            v-model="editedItem.tx_apellidos"
                                            label="Apellidos"
                                          ></v-text-field>

                                      </v-col>
                                    </v-row>
                                    <v-row
                                      cols="12"
                                      sm="6"
                                      md="4"
                                    >
                                      <v-col>

                                          <v-select
                                            v-model="editedItem.sw_activo"
                                            :items="d_status"
                                            class="input-group--focused"
                                            label="Status"
                                            dense
                                          ></v-select>                                           
                                      </v-col>

                                    </v-row>
                                    <v-row
                                      cols="12"
                                      sm="6"
                                      md="4"
                                    >
                                      <v-col>
                                          <v-text-field
                                            v-model="editedItem.tx_direccion"
                                            label="Dirección"
                                          ></v-text-field>
                                      </v-col>

                                    </v-row>


                                <v-row
                                  cols="12"
                                  sm="6"
                                  md="4"
                                >
                                <div class="w-full">
                                    <v-list>
                                      <v-list-group
                                        :value="false"
                                        prepend-icon="mdi-account-circle"
                                      >
                                        <template v-slot:activator>
                                          <v-list-item-title>Perfiles</v-list-item-title>
                                        </template>

                                      <v-list-item 
                                        v-for="(item, index) in perfiles" :key="index">
                                            <v-list-item-action>
                                                <v-radio-group v-model="editedItem.usuario_perfil">
                                                  <v-radio
                                                    :label=perfiles[index].tx_desc_perfil
                                                    :value=perfiles[index].nu_id_perfil
                                                  ></v-radio>
                                                </v-radio-group>                                              
                                            </v-list-item-action>

                                      </v-list-item>
                                      </v-list-group>
                                    </v-list>                                  
                                </div>                                
                                </v-row>
                                
                              </v-col>
                            </v-container>
                          </v-card-text>

                          <v-card-actions class="mb-10">
                            <v-spacer></v-spacer>
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="closeAdd"
                            >
                              Cancelar
                            </v-btn>
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="save"
                            >
                              Guardar
                            </v-btn>

                          </v-card-actions>
                        </v-card>
                      </v-dialog>


                      <v-dialog v-model="dialogDelete" max-width="500px">
                        <v-card>
                          <v-card-title class="headline">Esta seguro que desea eliminar este registro?</v-card-title>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="closeDelete">No</v-btn>
                            <v-btn color="blue darken-1" text @click="deleteItemConfirm">Si</v-btn>
                            <v-spacer></v-spacer>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </v-toolbar>
                  </template>
                  <template v-slot:item.actions="{ item }">
                    <v-icon
                      small
                      class="mr-2"
                    >
                      <!-- @click="editItem(item)"-->
                      mdi-account-search
                    </v-icon>
                    <v-icon
                      small
                      @click="deleteItem(item)"
                    >
                      mdi-delete
                    </v-icon>
                  </template>
                  <template v-slot:no-data>
                      No hay usuarios registrados
                  </template>
                </v-data-table>  
      
    </div>    
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { CREATE_USUARIO_TEMP, MUESTRA_USUARIO, LIST_PERFIL, MUESTRA_USUARIO_ROLES, UPDATE_USUARIO } from '@/store/actions.type'
import { SET_ERROR, SET_NOTIFICACION } from '@/store/mutations.type'

//import ThemifyIcon from "vue-themify-icons";

export default {
  name: 'ListarUsuarioView',
  data () {
    return {
      settings: [],
      editedItem: {
        nu_id_usuario: '',
        sw_activo: '',
        tx_username: '',
        tx_direccion: '',
        tx_nombres: '',
        tx_apellidos: '',
        tx_password: '',
        fh_clave_expira: '',
        tx_correo_usuario: '',
        nu_id_persona: '',
        usuario_perfil: '',
        co_status: 'REG'        
      },
      d_status: [
              { value: 'A', text: 'Activo' },
              { value: 'I', text: 'Inactivo', 
                disabled: false 
              }
            ],      
      usuarios: [],
      perfiles: {},
      dialog: false,
      dialogDelete: false,  
      dialogAdd: false,
      editedIndex: -1,    
      headers: [
        {
          text: 'Id Usuario',
          align: 'start',
          sortable: false,
          value: 'nu_id_usuario',
        },
        { text: 'Usuario', value: 'username' },
        { text: 'Correo', value: 'tx_correo_usuario' },
        { text: 'Nombres', value: 'tx_nombres' },
        { text: 'Apellidos', value: 'tx_apellidos' },
        { text: 'Acción', value: 'actions', sortable: false },
      ],  
      todas:[],
      showModal: false,
      message: 'Hello Vue.js!',
      search: '',
    
      currentSortDir: 'asc',
      totalItems: [],
      perPage: 10,
      limit: 10,
      disabled: "disabled",
      currentPage: 1,
      currentSort: 'default'
      
    }
  },
  computed: {
    ...mapGetters(['statusModalCliente','statusModalContacto', 'operacion','clientes']),
    formTitle () {
      return this.editedIndex === -1 ? 'Nuevo usuario' : 'Mostrar Permisos de Acceso'
    },
    splitedList(code, indice){
      console.log("code: ", code)
      return code.split('_')[indice]
    }          
  },
  components: {

  //  ThemifyIcon    
  },  
  watch: {
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },
    dialogAdd (val) {
      val || this.closeAdd()
    },

  },  
  methods: {
    objeto(obj) {
        return Object.keys(obj).map(key => {
          return {
            key,
            value: obj[key] || 'n/a',
          }
        })

    },
    randomNumber : function(){
      return Math.floor(Math.random() * (999999999 - 99999999 + 1)) + 1;
    },     
    total(item) {
      return (item.nu_cantidad * item.nu_precio)

    },
    save () {
        const formData = new FormData()
        this.editedItem["tx_password"] = this.randomNumber()
        for (const property in this.editedItem) {
            formData.append(`${property}`, `${this.editedItem[property]}`)
        }        
        if (this.editedIndex > -1) {

            this.$store.dispatch(UPDATE_USUARIO, formData).then(() => {
              Object.assign(this.perfiles[this.editedIndex], this.editedItem)
              this.$store.commit(SET_NOTIFICACION, {
                  color: "success",
                  text: "El perfil '" + this.editedItem.tx_desc_perfil + "' se actualizó  satisfactoriamente"
              })

            }, error => {
              this.$store.commit(SET_NOTIFICACION, {
                  color: "error",
                  text: error
              })
            }) 
        } else {
          this.$store.dispatch(CREATE_USUARIO_TEMP, formData).then(() => {          
              this.$store.commit(SET_NOTIFICACION, {
                  color: "success",
                  text: "El perfil '" + this.editedItem.tx_username + "' se registró satisfactoriamente"
              })
          }, error => {
              this.$store.commit(SET_NOTIFICACION, {
                  color: "error",
                  text: error
              })

          })             

        }
        this.close()
        this.closeAdd()
      },
      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },
      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },
      closeAdd () {
        this.dialogAdd = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },
      deleteItemConfirm () {
        console.log('this.editedIndex: ', this.editedIndex)
        //this.cartProducts.splice(this.editedIndex, 1)
        this.cartProductsDelete(this.editedIndex)

        this.closeDelete()
      },
      deleteItem (item) {
        this.itemSelect = item
        console.log('this.itemSelect: ', this.itemSelect) 
        this.editedIndex = this.cartProducts.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },
      addUsuario () {
        this.$store.dispatch(LIST_PERFIL, {}).then(response =>   {
          console.log('(MUESTRA_ROLES', response.rest.items)
          this.perfiles = Object.assign({}, response.rest.items)
        })            

        this.dialogAdd = true
      },
      editItem (item) {
        this.editedIndex = this.usuarios.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.$store.dispatch(MUESTRA_USUARIO_ROLES, {nu_id_usuario: item.nu_id_usuario}).then(response =>   {
          console.log('(MUESTRA_USUARIO_ROLES', response.rest.datos)
          this.usuario_roles = Object.assign({}, response.rest.datos)
        })            
        console.log('editedItem: ', item)
        this.dialog = true
      },                                  
    cerrar() {
                
    },     
    formatPrice(value) {
        let val = (value/1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },        

    checkout () {
      /*this.$store.dispatch(CHECKOUT, {productos: productos}).then(response => {
          this.codigosSeccion = response.rest.items
      }, error => {
          this.$store.commit(SET_ERROR, error.statusText)
      })            
      //this.$store.dispatch('cart/checkout', productos)
      */
    }

  },
  mounted () {
      this.$store.dispatch(MUESTRA_USUARIO, {}).then(response => {
         console.log("Pedido: ", response)

          this.usuarios = response.rest.datos.items              
      }, error => {
          this.$store.commit(SET_ERROR, error.statusText)
      })     
    
    //this.$store.dispatch(REFRESCAR_CLIENTE, {})
  },
  updated() {
    console.log('updated Persona: ')
    //this.refrescar()
  }

}
</script>
<style>

.tooltip {
  display: block !important;
  z-index: 10000;
}

.tooltip .tooltip-inner {
  background: black;
  color: white;
  border-radius: 16px;
  padding: 5px 10px 4px;
}

.tooltip .tooltip-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  margin: 5px;
  border-color: black;
}

.tooltip[x-placement^="top"] {
  margin-bottom: 5px;
}

.tooltip[x-placement^="top"] .tooltip-arrow {
  border-width: 5px 5px 0 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  bottom: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^="bottom"] {
  margin-top: 5px;
}

.tooltip[x-placement^="bottom"] .tooltip-arrow {
  border-width: 0 5px 5px 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
  top: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^="right"] {
  margin-left: 5px;
}

.tooltip[x-placement^="right"] .tooltip-arrow {
  border-width: 5px 5px 5px 0;
  border-left-color: transparent !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
  left: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip[x-placement^="left"] {
  margin-right: 5px;
}

.tooltip[x-placement^="left"] .tooltip-arrow {
  border-width: 5px 0 5px 5px;
  border-top-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  right: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip[aria-hidden='true'] {
  visibility: hidden;
  opacity: 0;
  transition: opacity .15s, visibility .15s;
}

.tooltip[aria-hidden='false'] {
  visibility: visible;
  opacity: 1;
  transition: opacity .15s;
}
</style>
