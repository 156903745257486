<template>
<div class="w-1/2 justify-center">
  <ValidationObserver ref="op" v-slot="{ }">
      <v-form 
          id="registro"
          ref="form1"
          @submit.prevent="">
          <v-container>
              <v-stepper v-model="e1">
                <v-stepper-header>
                  <v-stepper-step
                    :complete="e1 > 1"
                    step="1"
                  >

                  </v-stepper-step>

                  <v-divider></v-divider>

                  <v-stepper-step
                    :complete="e1 > 2"
                    step="2"
                  >
                  </v-stepper-step>

                  <v-divider></v-divider>
                  <v-stepper-step step="3">
                  </v-stepper-step>
                </v-stepper-header>
                  <div class="flex justify-center mt-1 text-sm font-bold"> 
                      {{vista[e1 - 1].descripcion }} 
                  </div>
                <v-stepper-items>
                <ValidationObserver ref="datos_basicos">
                  <v-stepper-content step="1">
                    <div class="mr-10 ml-10 mt-5">          
                      <validation-provider
                        v-slot="{ errors }"
                        name="fields.tx_username"
                        rules="required|email"
                      >
                        <v-row>
                              <v-text-field
                                v-model="fields.tx_username"
                                class="input-group--focused mb-4 text-lg"
                                :error-messages="errors"
                                label="Correo Electrónico"
                                required
                              ></v-text-field>
                        </v-row>
                      </validation-provider>

                      <validation-provider
                        v-slot="{ errors }"
                        name="fields.tx_apellidos"
                        rules="required"
                      >
                        <v-row>
                              <v-text-field
                                v-model="fields.tx_apellidos"
                                class="input-group--focused mb-4 text-lg"
                                :error-messages="errors"
                                label="Apellidos"
                                required
                              ></v-text-field>
                        </v-row>
                      </validation-provider>
                      <validation-provider
                        v-slot="{ errors }"
                        name="fields.tx_nombres"
                        rules="required"
                      >
                        <v-row>
                              <v-text-field
                                v-model="fields.tx_nombres"
                                class="input-group--focused mb-4 text-lg"
                                :error-messages="errors"
                                label="Nombres"
                                required
                              ></v-text-field>
                        </v-row>
                      </validation-provider>

                      <validation-provider
                        v-slot="{ errors }"
                        name="fields.tx_direccion"
                        rules="required"
                      >
                        <v-row>
                          <v-textarea
                              v-model="fields.tx_direccion"
                              class="input-group--focused mb-10 text-lg"
                              :error-messages="errors"
                              label="Dirección"
                              rows="2"
                              required

                          ></v-textarea>                      
                        </v-row>
                      </validation-provider>

                    </div>
                    
                    <div class="flex justify-between">
                      <div>
                          <v-btn  @click="cancelar()" depressed>
                            Cancelar
                          </v-btn>                        
                      </div>
                      <div>
                      <div>
                        <v-btn
                          depressed
                          color="primary"
                          @click="onSubmit_datos_basicos(1)"
                        >
                          Siguiente
                        </v-btn>                        
                      </div>
                    </div>
                  </div>
                  </v-stepper-content>
                </ValidationObserver>
                <ValidationObserver ref="datos_informacion">
                  <v-stepper-content step="2">
                    <v-card
                      class="mb-12"
                      height="400px"
                    >
                    </v-card>
                    <div class="flex justify-between">
                      <div>
                          <v-btn  class="mr-1" @click="anterior(2)" depressed>
                            Anterior
                          </v-btn>                        
                          <v-btn  @click="cancelar()" depressed>
                            Cancelar
                          </v-btn>                        
                      </div>
                      <div>
                        <v-btn
                          depressed
                          color="primary"
                          @click="onSubmit_informacion(2)"
                        >
                          Siguiente
                        </v-btn>                        
                      </div>
                    </div>
                  </v-stepper-content>
                </ValidationObserver>
                <ValidationObserver ref="datos_fotos">
                  <v-stepper-content step="3">
                    <v-card
                      class="mb-12"
                      height="400px"
                    >

                    </v-card>
                    <div class="flex justify-between">
                      <div class="mr-1">
                          <v-btn  class="mr-1" @click="anterior(3)" depressed>
                            Anterior
                          </v-btn>                        
                          <v-btn  @click="cancelar()" depressed>
                            Cancelar
                          </v-btn>                        
                      </div>
                      <div>
                        <v-btn
                          depressed
                          color="primary"
                          :loading="loading41"
                          :disabled="loading41"
                          @click="onComplete()"
                        >
                          Guardar
                          <template v-slot:loader>
                            <span class="custom-loader">
                              <v-icon light>mdi-cached</v-icon>
                            </span>
                          </template>
                        </v-btn>                        
                      </div>
                    </div>
                  </v-stepper-content>
                </ValidationObserver>
                </v-stepper-items>
              </v-stepper>
          </v-container>
        </v-form>     
    </ValidationObserver>

</div>
</template>
<script>
import { mapGetters } from 'vuex'
import { SET_NOTIFICACION, SET_ERROR, SET_MODAL_USUARIO_REGISTRAR } from '@/store/mutations.type'
import { MUESTRA_USUARIO, CREATE_USUARIO } from '@/store/actions.type'
//import MaskedInput from 'vue-masked-input'

import { extend } from 'vee-validate'
import { required, email, numeric, regex, min, max } from 'vee-validate/dist/rules'

extend('objectNotEmpty', {
  validate: (value) => {
    if (!value.producto) return false
    if (Object.keys(value.producto.code).length > 0) {
      return true
    }
    return false
  },
  message:
    'Debe seleccionar un valor.'
})

extend('regex', {
  ...regex,
  message: 'La primera letra debe se "V,G,J,C", seguido un guión, luego  regex digitos  seguido un guión, terminando con un digito'
})

extend('rif', {
  ...regex,
  message: 'La primera letra debe se "V,G,J,C", seguido un guión, luego  ocho(8)11 digitos  seguido un guión, terminando con un digito'
})
extend('tele', {
  ...regex,
  message: 'La primera letra debe se "V,G,J,C", seguido un guión, luego  ocho(8)222 digitos  seguido un guión, terminando con un digito'
})

extend('min', {
  ...min,
  message: 'Valor minimo 5'
})

extend("required", {
  ...required,
  message: "No puede estar vacio",
});

extend("max", {
  ...max,
  message: "No puede ser mayor a {length} caracteres",
});

extend('email', {
  ...email,
  message: 'Debe introducir un correo valido'
})
extend('numeric', {
  ...numeric,
  message: 'Debe introducir solo números'
})


export default {
  name: 'RegistrarUsuarioView',
  data () {
    return {
      e1: 1,
      showModal: false,
      loading41: false,
      mostrar_vista: {
          id: '1',
          descripcion: 'Datos básicos'
        }, 
      vista: [
        {
          id: '1',
          descripcion: 'Datos básicos'
        },
        {
          id: '2',
          descripcion: 'Roles'
        },
        {
          id: '3',
          descripcion: 'Empresa'
        },

      ],  
      descriptionLimit: 60,
      entries: [],
      isLoading: false,
      model: null,
      search: null,
      codigosSeccion: [],
      fields: {
        tx_username: null,
        tx_password: null,
        tx_password_confirm: null,
        tx_nombres: '',
        tx_apellidos: '',
        tx_direccion: '',
        co_status: 'REG'
      },
    }
  },
  computed: {
    ...mapGetters(['operacion','statusModalUsuario','usuarioRegistrado']),
    buscarUsuario () {
      return this.codUsuarios.map(entry => {
        const Description = entry.username.length > this.descriptionLimit
          ? entry.username.slice(0, this.descriptionLimit) + '...'
          : entry.username

        return Object.assign({}, entry, { Description })
      })
    },        

},
  components: {

  },
  watch: {
      search () {
      // Items have already been loaded
      if (this.buscarUsuario.length > 0) return

      // Items have already been requested
      if (this.isLoading) return

      this.isLoading = true

      // Lazily load input items
      this.$store.dispatch(MUESTRA_USUARIO, {}).then(response => {
          this.codUsuarios = response.rest.items
      }, error => {
          this.$store.commit(SET_ERROR, error.statusText)
      }).finally(() => (this.isLoading = false))      

      },           
  },    
  methods: {
    randomNumber : function(){
      return Math.floor(Math.random() * (20000 - 1 + 1)) + 1;
    },    
    genCodigo  () {
      this.fields.tx_cod_producto = `${this.fields.nu_id_seccion}-${this.randomNumber()}`
    
    },    
    limpiar_campos () {
      for (const property in this.fields) {
              this.fields[property] = null
      }    
    this.selected_cod_entidad = '',
    this.selected_cod_municipio = ''
    this.selected_cod_parroquia = ''
    this.e1 =  1
    },
    cancelar() {
        this.limpiar_campos()
        this.$store.commit(SET_MODAL_USUARIO_REGISTRAR, false)        
    },
    async onComplete () {
      const isValid = await this.$refs.datos_fotos.validate()
      if (!isValid) {
        return true
      }
      this.loading41 = true
      const formData = new FormData()
      this.fields["tx_fotos_json"] = JSON.stringify(this.fileInfosAll)

      for (const property in this.fields) {
          formData.append(`${property}`, `${this.fields[property]}`)
      }

      formData.append('oper', this.operacion.tipo)
      this.$store.dispatch(CREATE_USUARIO, formData).then(response =>   {
      this.$store.commit(SET_NOTIFICACION, {
          color: "success",
          text: "El producto '" + response.rest.items.tx_desc_corta_producto + "' se registró satisfactoriamente"
      })
      }).finally(() => (
        setTimeout(() => {
            this.loading41 = false,
            this.$store.commit(SET_MODAL_USUARIO_REGISTRAR, false)        
          },3000)               
        //this.e1 = 1
        //this.limpiar_campos_clientes()
        ))      
      
    },
    async onSubmit_datos_basicos (step) {
      const isValid = await this.$refs.datos_basicos.validate()
      if (isValid) {
        this.mostrar_vista = this.vista[step]
        this.e1 = this.e1 + 1
      }
    },
    async onSubmit_informacion (step) {
      const isValid = await this.$refs.datos_informacion.validate()
      if (isValid) {
        this.mostrar_vista = this.vista[step]
        this.e1 = this.e1 + 1
      }
    },
    anterior(step) {
        this.e1 = step - 1
    },
    buscarClientes() {
      //this.$store.dispatch(MUESTRA_CLIENTE_LISTA, {fe_cita: this.operacion_agenda.fecha}).then(response => {
      this.$store.dispatch(MUESTRA_USUARIO, {}).then(response => {
          this.codUsuarios = response.rest.items
          
      }, error => {
          this.$store.commit(SET_ERROR, error.statusText)
      })      
    },    

  },
  updated() {
    //this.fields.tx_apellidos = this.usuarioRegistrado[0].tx_apellidos
    //this.fields.tx_nombres = this.usuarioRegistrado[0].tx_nombres
    //console.log('datos Update: ', this.usuarioRegistrado )
  },
  mounted () {
    this.searchSeccion()
/*   this.fields.co_status_persona = 'REG'
    this.selected_cod_postal = '',
    this.selected_cod_captacion = ''

    this.buscarCodPostal('params')
    this.buscarTipoCaptacion('params')
          console.log("Montar : ", this.operacion.id)

  if (this.operacion.tipo === 'EDITAR') {
      for (var prop in this.operacion.id) {
      this.fields[prop] = this.operacion.id[prop]
      }
      this.fields["tx_cod_postal_json"] = JSON.parse(this.operacion.id["tx_cod_postal_json"])
      this.fields["tx_cod_captacion_json"] = JSON.parse(this.operacion.id["tx_cod_captacion_json"])
      this.selected_cod_postal = JSON.parse(this.operacion.id["tx_cod_postal_json"])              
      this.selected_cod_captacion = JSON.parse(this.operacion.id["tx_cod_captacion_json"]) 

    }
  */       
  }
}
</script>
<style>
  .custom-loader {
    animation: loader 1s infinite;
    display: flex;
  }
  @-moz-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-webkit-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-o-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
</style>