<template>
  <v-card
    class="mx-auto overflow-hidden"

  >
    <v-app-bar dense>
      <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>

      <v-toolbar-title class="text-lg">Administración</v-toolbar-title>
    </v-app-bar>
    <v-card-text>


        <v-tabs
          v-model="tab"
          align-with-title
        >
          <v-tabs-slider color="green"></v-tabs-slider>

          <v-tab>
           Gestión de usuario
          </v-tab>
          <v-tab>
          Gestión de perfiles
          </v-tab>
        </v-tabs>
          <v-tabs-items v-model="tab" class="h-full">
              <v-tab-item
            Gestión de usuario
              >
                    <ListarUsuarioView/>        
              </v-tab-item>
              <v-tab-item
                Gestión de perfiles
              >
                    <ListarPerfilView/>        
              </v-tab-item>            
          </v-tabs-items>      
    </v-card-text>
      
    <v-navigation-drawer
      v-model="drawer"
      absolute
      temporary
    >
      <v-list
        nav
        dense
      >
        <v-list-item-group
          v-model="group"
          active-class="deep-purple--text text--accent-4"
        >
          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-home</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Home</v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Account</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-dialog v-if="statusModalUsuarioRegistrar"
      v-model="dialogItemUsuario"
      persistent
      content-class="-mt-20" 
      scrollable
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"  
       
    >
      <v-card>
        <v-card-title class="flex justify-center">
          <span class="headline">Registrar usuario</span>
        </v-card-title>

        <v-card-text >
          <v-container >
              <div v-if="operacion.tipo === 'REGISTRO_USUARIO'" class="flex justify-center">
                <RegistrarUsuario />
              </div>            
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

  </v-card>
</template>
<script>
import { mapGetters } from 'vuex'
import { SET_MODAL_USUARIO_REGISTRAR, SET_OPERACION  } from '@/store/mutations.type'
import RegistrarUsuario from '@/components/administrador/RegistrarUsuarioView'
import ListarUsuarioView from '@/components/administrador/ListarUsuarioView'
import ListarPerfilView from '@/components/administrador/ListarPerfilView'

  export default {
    data: () => ({
      tab: null,
      items: [
        'Gestión de usuario', 'Preferencias generales', 'videos', 'images', 'news',
      ],
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',      
      drawer: false,
      group: null,
      dialogItemUsuario: false,
      dialogEditarItem: false,        
      editedItem: {
        nu_id_producto: '',
        tx_desc_corta_producto: 0,
        nu_precio: 0,
        nu_cantidad_disponible: 0,
        quantity: 0
      },        
    }),
    components: {
      RegistrarUsuario,
      ListarUsuarioView,
      ListarPerfilView,
    },
    computed: {
      ...mapGetters(['statusModalUsuarioRegistrar','operacion']),
    },
    methods: {
      addItem () {
        //this.editedItem = Object.assign({}, item)
        this.dialogItemUsuario = true
        this.$store.commit(SET_OPERACION, {tipo: 'REGISTRO_USUARIO', id: '', titulo:'Registrar usuario'})
        this.$store.commit(SET_MODAL_USUARIO_REGISTRAR, this.dialogItemUsuario)
      },
      close () {
        this.dialogItemUsuario = false
      },

    }
  }
</script>